import React from 'react'

import { Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { CodeBox } from '../../components/CodeBox'

export const Example = ({ noMargin = false }) => (
  <p className={noMargin ? 'mb-0' : undefined}>
    Esimerkiksi{' '}
    <span
      data-close="Sulje"
      data-term="Työkokeilu"
      title="Työkokeilussa henkilö voi selvittää ammatinvalinta- ja uravaihtoehtojaan tai mahdollisuuksiaan palata työmarkkinoille."
    >
      työkokeilu
    </span>
    , palkkatuki ja terveystarkastuksessa käyminen voivat auttaa sinua
    selvittämään työkykyäsi ja soveltuvuuttasi eri paikkoihin ja aloille. Tukea
    voidaan myöntää esimerkiksi työn olosuhteiden järjestelyyn työpaikalla. Myös
    ammatinvalinta- ja uraohjaus sekä{' '}
    <span
      data-close="Sulje"
      data-term="Koulutusneuvonta"
      title="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus aut a quidem est, perferendis asperiores repellat nisi beatae culpa animi."
    >
      koulutusneuvonta
    </span>{' '}
    voivat osaltaan auttaa selvittämään sinulle sopivia vaihtoehtoja,
    erityisesti silloin, jos alan vaihtaminen tai lisäkouluttautuminen on
    sinulle ajankohtaista.
  </p>
)

const Page = () => (
  <Content
    heading="Term"
    subHeading="Verkkopalvelu"
  >
    <Paragraph>
      Sisältötekstin termistöä voi täydentää tarkennuksen sisältävällä
      dialogilla ympäröimällä haluttu sana <Code>&lt;span&gt;</Code>
      -elementillä ja <Code>data-term</Code>, <Code>title</Code> sekä{' '}
      <Code>data-close</Code> -attribuuteilla.
    </Paragraph>
    <Playground
      format="html"
      hideCode
    >
      <Example noMargin />
    </Playground>
    <CodeBox language="html">
      {
        '<span data-close="Sulje" data-term="Työkokeilu" title="Työkokeilussa henkilö voi selvittää ammatinvalinta- ja uravaihtoehtojaan tai mahdollisuuksiaan palata työmarkkinoille.">työkokeilu</span>'
      }
    </CodeBox>
  </Content>
)

export default Page
